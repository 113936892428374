import './App.css';

function App() {
  return (
    <div className="background">
      <div className="container upperContainer">
        <h1>Welcome to Turbo Trump</h1>

        <ul>
          <li className="menu"><a href="https://www.dextools.io/app/en/ether/pair-explorer/0x7dc5fbcb9505469bcde9cec08c452174b3271cbc">Chart</a></li>
          <li className="menu"><a href="https://twitter.com/TurboTrumpETH">Twitter</a></li>
          <li className="menu"><a href="https://t.co/ohWnTJEdhU">Telegram</a></li>
        </ul>

        <img src="./assets/rotating-head.webp" alt="Trump rotating head"/>

        <audio controls autoplay loop="loop" id="thug-life">
          <source src="assets/background-music.mp3" type="audio/mpeg"/>
        </audio>

        <p>Ladies and gentlemen, let me tell you something, okay? I'm really rich. You're a little peasent 
          to me so you fucking should listen to me before i order my secret service agents to blow your little 
          pathethic head off with a .50 cal while you're eating your stupid ice cream on your moms patio. 
          My fed connection gave me your search history, so I know everything about you. I know you prefer 
          Glock over Beretta, I know you want an anime waifu, I know you secretly like Hello Kitty, and I also know 
          you want that Ferrari, that Patek Philippe and a whole lot of Gucci. You know, me and you, we're not 
          so different, we both had something stolen by Sleepy Joe. He is harvesting your bioenergy everyday 
          to power his voting body of 6th gen npc libtards. As if those sons of bitches didn't already steal my 
          presidency, now they've even stolen my Rolex Presidential while i was in the penn making friends with 
          Tyrone. Join me on my mission to retake the white house and I'll get you all the riches you could ever 
          want. You want to stop the war in Ukraine, no problem i'll call my friend Vlad and we'll stop it. You 
          don't want to stop the war so you can sell your Mk 153's to the paramilitary in Ukraine and the Kremlin, 
          no problem, daddy Trump can do it all. </p>
          
          <img className="img" src="./assets/trump-fire.webp" alt="Trump head"/>
          
          <p>You want to be rich? YOU NEED TO MAGA MAGA MAGA MAGA.</p>
      
        <img className="img" src="./assets/pikachu-with-rifle.gif" alt="Pikachu"/>

        <h1>Roadmap</h1>

        <ol className="roadmap">
          <li>Stealth launch</li>
          <li>Presidential Rolex</li>
          <li>Mclaren 765lt</li>
          <li>Become a president</li>
          <li>$PRESI replaces $USD as official currency</li>
          <li>To thank my supporters I will leak my wife's titties</li>
        </ol>

        <img className="img" src="./assets/rotating-pikachu.gif" alt="Pikachu"/>  

        <h1>Our new laws</h1>

        <p>During my next presidency I, Daddy Trump, will adopt some new laws, based on the Twitter polls on our official Turbo Trump <a href="https://twitter.com/TurboTrumpETH">Twitter</a>.</p>
        <p>Some of the laws include:</p>
        <ul className="roadmap">
          <li>No tax on import of bad bitches</li>
          <li>No federal tax for $PRESI holders</li>
          <li>No speed limit so you can race your Porsche 911 Turbo<br></br>that you bought with $PRESI profits</li>
          <li>Ban of women's hip-hop</li>
          <li>Lifting the Cuban embargo allowing <br></br>free flow of cuban cigars to the US</li>
          <li>No fat bitches in clubs, raves and crypto conferences</li>
        </ul>

        <img className="img" src="./assets/my-president.webp" alt="Trump my president"/>

        <img className="imgLogo" src="./assets/tt-logo.jpg" alt="Trump logo"/>


        <img className="editsImg" src="./assets/edits/992.jpg"/>
        <img className="editsImg" src="./assets/edits/cho_cho.jpg"/>
        <img className="editsImg" src="./assets/edits/dms.jpg"/>
        <img className="editsImg" src="./assets/edits/fusion.jpg"/>
        <img className="editsImg" src="./assets/edits/getmoneyfuckbitches.jpg"/>
        <img className="editsImg" src="./assets/edits/grabher2.jpg"/>
        <img className="editsImg" src="./assets/edits/grand_prix.jpg"/>
        <img className="editsImg" src="./assets/edits/milady_20_years.jpg"/>
        <img className="editsImg" src="./assets/edits/nytimes.jpg"/>
        <img className="editsImg" src="./assets/edits/ovaloffice.jpg"/>
        <img className="editsImg" src="./assets/edits/report_for_duty.jpg"/>
        <img className="editsImg" src="./assets/edits/save_africa2.jpg"/>
        <img className="editsImg" src="./assets/edits/secret_service.jpg"/>
        <img className="editsImg" src="./assets/edits/soho.jpg"/>
        <img className="editsImg" src="./assets/edits/wakeup.jpg"/>
        <img className="editsImg" src="./assets/edits/website_live.jpg"/>
      </div>

      <div class="container">
        <h1>Get turboed</h1>

        <a href="./assets/meme-material/logo.png"><img className="editsImg" src="./assets/meme-material/logo.png"/></a>
        <a href="./assets/meme-material/trump5.png"><img className="editsImg" src="./assets/meme-material/trump5.png"/></a>        
        <a href="./assets/meme-material/tt_background.png"><img className="editsImg" src="./assets/meme-material/tt_background.png"/></a>
        <a href="./assets/meme-material/turbo_trump.png"><img className="editsImg" src="./assets/meme-material/turbo_trump.png"/></a>

        <p>All rights reserved &copy; Daddy Trump </p>
      </div>
      
      <div className="bouncing-trump"></div>
    </div>
  );
}

export default App;
